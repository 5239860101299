import { render, staticRenderFns } from "./AccountPlanIncomesOverview.vue?vue&type=template&id=28af1b61&"
import script from "./AccountPlanIncomesOverview.vue?vue&type=script&lang=js&"
export * from "./AccountPlanIncomesOverview.vue?vue&type=script&lang=js&"
import style0 from "./AccountPlanIncomesOverview.vue?vue&type=style&index=0&id=28af1b61&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
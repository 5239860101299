<template>
  <div>
    <b-row no-gutters>
      <b-col class="col-12 col-md-6 pl-3 pl-md-4">
        <h2 class="text-gray">{{ $t( translationPath + 'title_manage_income_categories') }}</h2>
      </b-col>
      <b-col class="col-12 col-md-6 pr-3 pr-md-4">
        <b-link class='btn btn-xs btn-outline-secondary float-right mb-2 mb-md-0' to='/incomes-expenses/accounts/create/income'>
          <i class="flaticon stroke plus"></i> {{ $t(translationPath + 'new_income_category') }}
        </b-link>
        <b-link class='btn btn-xs btn-outline-secondary float-right mr-1 mr-xs-0 mr-sm-2 mr-md-3 mb-2 mb-md-0' to='/incomes-expenses/accounts/groups/create/income'>
          <i class="flaticon stroke plus"></i> {{ $t( translationPath + 'new_income_category_group') }}
        </b-link>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col cols="12" class="pl-4">
        <p class="text-gray">{{ $t( translationPath + 'table_description') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AccountPlanIncomesHeader',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  }
}
</script>
